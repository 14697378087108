import { Request, RequestEnvelope, Response, ResponseEnvelope } from "./worker";

export class WorkerManager {
  worker: Worker;
  idx: number;
  lookup: Map<
    number,
    {
      symbol: Symbol;
      resolve: (response: Response | PromiseLike<Response>) => void;
    }
  >;
  currentLookupForSymbol: Map<Symbol, number>;
  constructor(worker: Worker) {
    this.worker = worker;
    this.idx = 0;
    this.lookup = new Map();
    this.currentLookupForSymbol = new Map();
    worker.onmessage = (event: MessageEvent<ResponseEnvelope>) => {
      const resolver = this.lookup.get(event.data.unique);
      this.lookup.delete(event.data.unique);
      const response = event.data;
      if (resolver) {
        if (
          this.currentLookupForSymbol.get(resolver.symbol) === event.data.unique
        ) {
          this.currentLookupForSymbol.delete(resolver.symbol);
          resolver.resolve(response);
        } else {
          // Got a response for a particular symbol, but it isn't the most recent one"
        }
      }
    };
  }

  coinUnique(): number {
    const freshIdx = this.idx;
    this.idx += 1;
    return freshIdx;
  }

  evaluate(request: Request, symbol: Symbol): Promise<Response> {
    const unique = this.coinUnique();
    const promise = new Promise<Response>((resolve) => {
      this.lookup.set(unique, {
        symbol,
        resolve,
      });
    });

    this.currentLookupForSymbol.set(symbol, unique);

    const requestEnvelope: RequestEnvelope = { ...request, unique };
    this.worker.postMessage(requestEnvelope);
    return promise;
  }

  createInstance(): WorkerManagerInstance {
    return new WorkerManagerInstance(this);
  }
}

export class WorkerManagerInstance {
  manager: WorkerManager;
  id: Symbol;
  constructor(manager: WorkerManager) {
    this.manager = manager;
    this.id = Symbol();
  }

  evaluate(request: Request): Promise<Response> {
    return this.manager.evaluate(request, this.id);
  }
}
